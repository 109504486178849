<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center"
    :class="headerClasses"
  >
    <!--begin::Logo-->
    <a href="/">
      <img
        alt="Logo"
        :src="layoutConfig('self.logo.default')"
        class="logo-default max-h-30px"
      />
    </a>
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <!--begin::Aside Mobile Toggle-->
      <button
        v-if="asideEnabled"
        class="btn p-0 burger-icon rounded-0 burger-icon-left"
        id="kt_aside_mobile_toggle"
      >
        <span></span>
      </button>
      <!--end::Aside Mobile Toggle-->
      <!--begin::Header Menu Mobile Toggle-->
      <button
        class="btn p-0 burger-icon ml-4"
        id="kt_aside_tablet_and_mobile_toggle"
        ref="kt_aside_tablet_and_mobile_toggle"
      >
        <span>
          <inline-svg src="media/svg/icons/Text/Align-left.svg" />
        </span>
      </button>
      <!--end::Header Menu Mobile Toggle-->
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";

export default {
  name: "KTHeaderMobile",
  components: {},
  mounted() {
    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.sticky");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.header-mobile {
  background-color: #65a0bf;
}
</style>
