<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header header-fixed"
    :class="headerClasses"
  >
    <div
      class="d-flex align-items-stretch justify-content-between container-fluid"
    >
      <div class="d-none d-lg-flex align-items-center mr-3">
        <!--begin::Aside Toggle-->
        <button
          id="kt_aside_desktop_toggle"
          ref="kt_aside_desktop_toggle"
          class="btn btn-icon aside-toggle ml-n3 mr-10"
        >
          <span class="svg-icon svg-icon-xxl svg-icon-dark-75">
            <!--begin::Svg Icon | path:assets/media/svg/icons/Text/Align-left.svg-->
            <inline-svg src="media/svg/icons/Text/Align-left.svg" />
            <!--end::Svg Icon-->
          </span>
        </button>
        <!--end::Aside Toggle-->
        <div class="header-logo">
          <a href="https://observatoriodescarbonizacionrural.com/">
            <img
              alt="Logo"
              :src="layoutConfig('self.logo.default')"
              class="logo-sticky max-h-45px"
            />
          </a>
        </div>
      </div>

      <div class="pt-5 d-none d-sm-block">
        <v-btn class="mr-2" to="/" small> Inicio </v-btn>
        <v-btn class="mr-2 btn-ecosistema-ve" to="/vehiculo-electrico" small>
          Vehiculo eléctrico
        </v-btn>
        <v-btn class="mr-2 btn-ecosistema-aut" to="/autoconsumo" small>
          Autoconsumo
        </v-btn>
        <v-btn class="mr-2 btn-ecosistema-alm" to="/almacenamiento" small>
          Almacenamiento
        </v-btn>
        <v-btn
          class="mr-2 btn-ecosistema-elc"
          to="/nivel-electrificacion"
          small
        >
          Electrificación
        </v-btn>

        <v-btn class="mr-2 btn-ecosistema-con" to="/concienciacion" small>
          Concienciación
        </v-btn>
      </div>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";

import KTLayoutHeader from "@/assets/js/layout/base/header.js";

export default {
  name: "KTHeader",
  components: {},
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if header container is fluid
     */
    widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.ecosistema {
  color: #ffffff;
}
.v-application code {
  box-shadow: none !important;
}

.titulo {
  font-size: 1.4em;
}

.dato {
  font-size: 1.2em;
}

.ecosistema-ve {
  border-left: 5px solid #295f56 !important;
}
.ecosistema-aut {
  border-left: 5px solid #63ad41 !important;
}
.ecosistema-elc {
  border-left: 5px solid #439dd9 !important;
}
.ecosistema-alm {
  border-left: 5px solid #133682 !important;
}
.ecosistema-con {
  border-left: 5px solid #666666 !important;
}
.ecosistema-gen {
  border-left: 5px solid #96cdd6 !important;
}

.texto-ecosistema-ve {
  color: #295f56 !important;
}
.texto-ecosistema-aut {
  color: #63ad41 !important;
}
.texto-ecosistema-elc {
  color: #439dd9 !important;
}
.texto-ecosistema-alm {
  color: #133682 !important;
}
.texto-ecosistema-con {
  color: #666666 !important;
}
.texto-ecosistema-gen {
  color: #96cdd6 !important;
}

.btn-ecosistema-ve {
  border: 2px solid #295f56 !important;
}
.btn-ecosistema-aut {
  border: 2px solid #63ad41 !important;
}
.btn-ecosistema-elc {
  border: 2px solid #439dd9 !important;
}
.btn-ecosistema-alm {
  border: 2px solid #133682 !important;
}
.btn-ecosistema-con {
  border: 2px solid #666666 !important;
}
</style>
